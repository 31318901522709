/**
** JAVASCRIPTS
** Name: Navigation
********************************************************************************/

(function() {
  'use strict';


  if(document.querySelectorAll('.js-navigation').length > 0) {


    /**
    ** Variables
    ****************************************/

    const $items = document.querySelectorAll('.js-navigation-item');
    const $links = document.querySelectorAll('.js-navigation-link');


    /**
    ** Events
    ****************************************/

    $links.forEach(($link) => {
      $link.addEventListener('click', (event)=>{
        event.preventDefault();

        const $item = $link.closest('.js-navigation-item');

        if($item.classList.contains('is-active')) {
          $item.classList.remove('is-active');
        }
        else {
          if(document.querySelector('.js-navigation-item.is-active')) {
            document.querySelector('.js-navigation-item.is-active').classList.remove('is-active');
          }
          $item.classList.add('is-active');
        }
      });
    });


  }

})();
