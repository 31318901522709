/**
** JAVASCRIPTS
** Name: Location
********************************************************************************/

(function() {
  'use strict';


  if(document.querySelector('.js-location')) {


    /**
    ** Variables
    ****************************************/

    const $html     = document.documentElement;
    const $location = document.querySelector('.js-location');
    const $open     = document.querySelector('.js-location-button');
    const $overlay  = document.querySelector('.js-overlay');
    const $close    = document.querySelector('.js-overlay-button');


    /**
    ** Events
    ****************************************/

    $open.addEventListener('click', ()=>{
      $html.classList.add('is-overlayed');
    });

    $close.addEventListener('click', ()=>{
      $html.classList.remove('is-overlayed');
    });


  }

})();
