/**
** JAVASCRIPTS
** Name: Footer
********************************************************************************/

(function() {
  'use strict';


  if(document.querySelector('.js-footer')) {


    /**
    ** Variables
    ****************************************/

    const $html        = document.documentElement;
    const $footer      = document.querySelector('.js-footer');
    const $group       = document.querySelector('.js-footer-group');
    const $placeholder = document.querySelector('.js-footer-placeholder');
    const $columns     = document.querySelectorAll('.js-footer-column');


    /**
    ** Events
    ****************************************/


    // Toggle

    $placeholder.addEventListener('click', ()=>{
      $group.classList.toggle('is-active');
    });


    // Open/close

    $columns.forEach(($column) => {
      const $subtitle = $column.querySelector('.js-footer-subtitle');

      $subtitle.addEventListener('click', ()=>{
        $column.classList.toggle('is-active');
      });
    });


    // Global click

    document.addEventListener('click', (event)=>{
      if(document.querySelector('.js-footer-group.is-active') &&
        !event.target.classList.contains('js-footer-placeholder') &&
        !event.target.closest('.js-footer-placeholder')) {
        document.querySelector('.js-footer-group.is-active').classList.remove('is-active');
      }
    });


  }

})();
