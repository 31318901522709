/**
** JAVASCRIPTS
** Name: Slider
********************************************************************************/


/**
** Pre-imports
****************************************/

import $ from 'jquery';
import 'slick-carousel';


/**
** SLIDERS
****************************************/

(function() {
  'use strict';

  if($('.js-slider').length > 0) {

    $('.js-slider').each(function() {
      var $slider = $(this),
          $items  = $slider.find('.js-slider-items'),
          $prev   = $slider.find('.js-slider-prev'),
          $next   = $slider.find('.js-slider-next'),
          $number = $slider.find('.js-slider-number'),
          $total  = $slider.find('.js-slider-total');

      // SET TOTAL
      $items.on('init', function(event, slick) {
        $number.html(slick.currentSlide + 1);
        $total.html(slick.slideCount);
      });

      // SET CURRENT NUMBER
      $items.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
        $number.html(nextSlide + 1);
      });

      // INIT
      $items.slick({
        autoplay: false,
        speed: 500,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        mobileFirst: true
      });

      // PREV
      $prev.on('click', function() {
        $items.slick('slickPrev');
      });

      // NEXT
      $next.on('click', function() {
        $items.slick('slickNext');
      });

    });

  }

})();
