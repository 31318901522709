/**
 ** JAVASCRIPTS
 ** Nom: Navigation
 ********************************************************************************/

(function () {
  ('use strict');

  // Tableau pour stocker les champs cachés recaptcha
  let grecaptchaHiddenFields = [];
  // Vérifier si recaptcha est chargé globalement
  let grecaptchaGlobalLoaded = false;

  // Callback global pour recaptcha
  window.grecaptchaGlobalCallback = () => {
    grecaptchaGlobalLoaded = true;

    // Exécute recaptcha pour chaque champ caché
    grecaptchaHiddenFields.forEach($hidden => {
      const action = $hidden.dataset.recaptchaAction;
      const siteKey = $hidden.dataset.recaptchaSiteKey;

      grecaptcha.ready(() => {
        grecaptcha.execute(siteKey, { action }).then(token => {
          $hidden.value = token;

          // Soumet le formulaire si nécessaire
          if ($hidden.dataset.submitMe) {
            $hidden.closest('form').submit();
          }
        });
      });
    });
  };

  document.addEventListener('DOMContentLoaded', () => {
    let grecaptchaGlobalLoadInited = false;
    const autoloadrecaptchaForms = [];

    // Fonction pour initialiser recaptcha sur un formulaire
    const initrecaptcha = $form => {
      if ($form.classList.contains('g-recaptcha--inited')) return;
      $form.classList.add('g-recaptcha--inited');

      const $hidden = $form.querySelector('input[name="g-recaptcha-response"]');
      grecaptchaHiddenFields.push($hidden);

      if (grecaptchaGlobalLoaded) {
        grecaptchaGlobalCallback();
        return;
      }

      if (grecaptchaGlobalLoadInited) return;
      grecaptchaGlobalLoadInited = true;

      // Charger le script recaptcha
      const siteKey = $hidden.dataset.recaptchaSiteKey;
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}&onload=grecaptchaGlobalCallback`;
      document.body.appendChild(script);
    };

    // Gérer la soumission du formulaire
    const submitForm = event => {
      const $form = event.target;
      const $submit = $form.querySelector('button[type=submit]');

      if (!$submit) return;

      if ($submit.getAttribute('aria-disabled') === 'true') {
        event.preventDefault();
        return false;
      }

      $submit.setAttribute('aria-disabled', 'true');

      const $hidden = $form.querySelector('input[name="g-recaptcha-response"]');
      if ($hidden.value) {
        return true;
      }

      $hidden.dataset.submitMe = true;
      initrecaptcha($form);
      event.preventDefault();
      return false;
    };

    // Initialiser recaptcha pour tous les formulaires concernés
    document.querySelectorAll('.g-recaptcha').forEach($submit => {
      const $form = $submit.closest('form');
      const $hidden = $form.querySelector('input[name="g-recaptcha-response"]');
      $form.addEventListener('submit', submitForm);

      if (!$hidden) return;

      $form.setAttribute('novalidate', '');

      if ($submit.matches('button[type=submit]')) {
        autoloadrecaptchaForms.push($form);
      }

      // Gestion des cookies
      window._axcb = window._axcb || [];
      window._axcb.push(function (sdk) {
        sdk.on('cookies:complete', function (choices) {
          document.querySelectorAll('[data-hide-on-vendor-consent="recaptcha"]').forEach(el => {
            const vendor = el.getAttribute('data-hide-on-vendor-consent');
            el.style.display = choices[vendor] ? 'none' : 'inherit';
            const submitFormrecaptcha = document.querySelectorAll('.submit-form-recaptcha');
            if (submitFormrecaptcha.length > 0) {
              submitFormrecaptcha.forEach(submit => {
                submit.style.display = choices[vendor] ? 'inherit' : 'none';
              });
            }
          });
        });
      });
    });
  });
})();
