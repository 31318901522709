/**
** JAVASCRIPTS
** Name: Header
********************************************************************************/

(function() {
  'use strict';


  if(document.querySelector('.js-header')) {


    /**
    ** Variables
    ****************************************/

    const $html   = document.documentElement;
    const $body   = document.querySelector('body');
    const $header = document.querySelector('.js-header');
    const $toggle = document.querySelector('.js-header-toggle');
    const $main   = document.querySelector('.js-main');

    var offsetTop     = 1;
    var lastScrollPos = 0;
    var ticking       = false;
    var headerHeight  = $header.offsetHeight;

    const is_admin = $body.classList.contains('admin-bar');

    if(is_admin) {
      $main.style.paddingTop = `${headerHeight + 50}px`;
    }
    else {
      $main.style.paddingTop = `${headerHeight}px`;
    }


    /**
    ** Functions
    ****************************************/

    function setHeaderState(scrollPos, direction) {

      if(scrollPos >= offsetTop) {
        $html.classList.add('header-fixed');
      }
      else {
        $html.classList.remove('header-fixed');
      }

      if(direction > 0) {
        $html.classList.add('header-hidden');
        if(window.innerWidth > 959 && document.querySelector('.js-navigation-item.is-active')) {
          document.querySelector('.js-navigation-item.is-active').classList.remove('is-active');
        }
      }
      else {
        $html.classList.remove('header-hidden');
      }
    }


    /**
    ** Events
    ****************************************/


    // Toggle

    $toggle.addEventListener('click', ()=>{
      $html.classList.toggle('menu-active');
    });



    // Scroll

    window.addEventListener('scroll', ()=>{
      var scrollPos = window.pageYOffset || document.documentElement.scrollTop;
      var direction = scrollPos > lastScrollPos ? 1 : -1;

      if (!ticking) {
        window.requestAnimationFrame(function() {
          setHeaderState(scrollPos, direction);
          ticking = false;
        });
      }

      lastScrollPos = (scrollPos <= 0) ? 0 : scrollPos;
      ticking = true;
    });



    // Resize

    window.addEventListener('resize', ()=>{
      headerHeight = $header.offsetHeight;

      if(is_admin) {
        $main.style.paddingTop = `${headerHeight + 50}px`;
      }
      else {
        $main.style.paddingTop = `${headerHeight}px`;
      }
    });



    // Info

    if(document.querySelector('.js-header-info')) {
      const $info  = document.querySelector('.js-header-info');
      const $close = document.querySelector('.js-header-close');

      $body.classList.add('header-has-info');

      $close.addEventListener('click', ()=>{
        $info.remove();
        $body.classList.remove('header-has-info');
        headerHeight = $header.offsetHeight;
        sessionStorage.setItem('headerInfoClosed', 'true');

        if(is_admin) {
          $main.style.paddingTop = `${headerHeight + 50}px`;
        }
        else {
          $main.style.paddingTop = `${headerHeight}px`;
        }
      });
    }


    // Global click

    document.addEventListener('click', (event)=>{
      if(document.querySelector('.js-navigation-item.is-active') &&
        !event.target.classList.contains('js-navigation-link') &&
        !event.target.closest('.js-navigation-link')) {
        document.querySelector('.js-navigation-item.is-active').classList.remove('is-active');
      }
    });


  }

})();
