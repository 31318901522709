/**
** JAVASCRIPTS
** Name: Popin
********************************************************************************/


/**
** Pre-imports
****************************************/

import A11yDialog from 'a11y-dialog';

(function() {
  'use strict';

  document.addEventListener('DOMContentLoaded', () => {
    const popinsElements = document.querySelectorAll('[data-popin]');
    if (!popinsElements.length) {
      return;
    }

    const popins = [];
    popinsElements.forEach((element) => {
      const popin = new Popin(element);
      popin.mount();
      popins.push(popin);
    });

    const popinsOpenersElements = document.querySelectorAll('.js-popin-opener');
    popinsOpenersElements.forEach((element) => {
      const popinOpener = new PopinOpener(element, popins);
      popinOpener.mount();
    });
  });
})();

class PopinOpener {
  constructor(element, popins) {
    this.element = element;
    this.popins = popins;

    this.open = this.open.bind(this);
  }

  mount() {
    this.element.addEventListener('click', this.open);
  }

  open() {
    this.popins.every((popin) => {
      if (popin.element.getAttribute('id') == this.element.dataset.popinTarget) {
        popin.open();
        return false;
      }

      return true;
    });
  }
}

class Popin {
  constructor(element) {
    this.element = element;
    this.dialog;
    this.trigger;

    this.handleHide = this.handleHide.bind(this);
  }

  mount() {
    this.dialog = new A11yDialog(this.element);

    this.dialog.on('show', (element, event) => {
      if(event) {
        this.trigger = event.target;
      }
    });

    const popinLinks = this.element.querySelectorAll('a');
    for (let i = 0; i < popinLinks.length; i++) {
      popinLinks[i].addEventListener('click', this.handleHide);
    }

    this.dialog.on('hide', this.handleHide);
  }

  unmount() {
    this.dialog.destroy();
  }

  open() {
    this.dialog.show();
  }

  handleHide() {
    this.trigger = null;
  }
}
