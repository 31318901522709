import Tablist from './Tablist';

document.addEventListener('DOMContentLoaded', () => {
  const tablists = document.querySelectorAll('[data-tablist]');
    if (!tablists.length) {
      return;
    }
    tablists.forEach((element) => {
      const tablist = new Tablist(element);
      tablist.mount();
    });
});
