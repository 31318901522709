/**
** JAVASCRIPTS
** Name: Areas
********************************************************************************/

(function() {
  'use strict';


  if(document.querySelector('.js-areas')) {


    /**
    ** Variables
    ****************************************/

    const $html          = document.documentElement;
    const $areas         = document.querySelector('.js-areas');
    const $contents      = document.querySelectorAll('.js-areas-content');
    const $illustrations = document.querySelectorAll('.js-areas-illustration');

    var bodyRect     = document.body.getBoundingClientRect();
    var windowHeight = window.innerHeight;
    var checkpoints  = [];
    var nbElements   = 0;

    let lastKnownScrollPosition = 0;
    let ticking = false;

    var active = false;


    /**
    ** FUNCTION
    ** Update Variables
    ****************************************/

    function updateVariables() {
      active = window.innerWidth >= 960 ? true : false;

      if(active) {
        bodyRect     = document.body.getBoundingClientRect();
        windowHeight = window.innerHeight;
        checkpoints  = [];
        nbElements   = 0;

        $contents.forEach(($content) => {
          var elemRect   = $content.getBoundingClientRect();
          var offset     = Math.round(elemRect.top - bodyRect.top);
          var checkpoint = offset - (windowHeight / 2);

          $content.dataset.checkpoint = checkpoint;
          checkpoints.push(checkpoint);
          nbElements++;
        });

        checkpoints.reverse();
      }
    }


    /**
    ** FUNCTION
    ** Update Areas
    ****************************************/

    function updateAreas(scrollPos) {
      var skip = false;

      checkpoints.forEach((checkpoint, index) => {
        if(!skip && scrollPos >= checkpoint) {
          var elementIndex = nbElements - index;
          var $target      = document.querySelector('.js-areas-illustration:nth-child(' + elementIndex + ')');

          if(!$target.classList.contains('is-active')) {
            if(document.querySelector('.js-areas-illustration.is-active')) {
              document.querySelector('.js-areas-illustration.is-active').classList.remove('is-active');
            }
            $target.classList.add('is-active');
          }

          skip = true;
        }
      });
    }


    /**
    ** EVENT
    ** Load
    ****************************************/

    window.addEventListener('load', function () {
      updateVariables();
    });


    /**
    ** EVENT
    ** Resize
    ****************************************/

    window.addEventListener('resize', function () {
      updateVariables();
    });


    /**
    ** EVENT
    ** Scroll
    ****************************************/

    document.addEventListener("scroll", (event) => {
      lastKnownScrollPosition = window.scrollY;

      if(active && !ticking) {
        window.requestAnimationFrame(() => {
          updateAreas(lastKnownScrollPosition);
          ticking = false;
        });

        ticking = true;
      }
    });





  }

})();
