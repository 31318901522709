// https://developers.axeptio.eu/v/francais/integration/consentement-contextuel

window._axcb = window._axcb || [];
window._axcb.push(function (sdk) {
  sdk.on('cookies:complete', function (choices) {
    document.querySelectorAll('[data-hide-on-vendor-consent]').forEach(el => {
      const vendor = el.getAttribute('data-hide-on-vendor-consent');
      el.style.display = choices[vendor] ? 'none' : 'inherit';
    });
    document.querySelectorAll('[data-requires-vendor-consent]').forEach(el => {
      const vendor = el.getAttribute('data-requires-vendor-consent');
      if (choices[vendor]) {
        if (el.hasAttribute('data-src')) {
          el.setAttribute('src', el.getAttribute('data-src'));
        }
      }
    });
  });
});
